<template>
    <vx-card
        v-if="willsToApprove"
        title="Approve"
        collapse-action
        refresh-content-action
        @refresh="refreshApprove"
    >
        <ToApproveTable
            :wills="willsToApprove.data"
            class="mb-6"
        ></ToApproveTable>
        <div v-if="willsToApprove.total">
            <div class="flex justify-between flex-wrap">
                <span>
                    <vs-chip
                        :color="timeColour(willsToApprove.oldest, 'approve')"
                    >
                        {{ willsToApprove.total }}
                    </vs-chip>
                </span>
                <span>
                    <vs-button
                        color="primary"
                        type="border"
                        to="/wills/pipeline/to_approve"
                        >View All</vs-button
                    >
                </span>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { tableHelpers } from '@/mixins/tableHelpers'

import ToApproveTable from '../Table/ToApprove'

export default {
    name: 'ToApproveCard',
    mixins: [tableHelpers],
    components: {
        ToApproveTable,
    },
    computed: {
        willsToApprove() {
            return this.$store.getters.willsToApprove
        },
    },
    methods: {
        refreshApprove(card) {
            this.$store.dispatch('willsToApproveFetch')
            card.removeRefreshAnimation(2000)
        },
    },
}
</script>
