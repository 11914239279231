<template>
    <vx-card
        v-if="willsToContact"
        title="Contact"
        collapse-action
        refresh-content-action
        @refresh="refreshContact"
    >
        <ToContactTable
            :wills="willsToContact.data"
            class="mb-6"
        ></ToContactTable>
        <div v-if="willsToContact.total">
            <div class="flex justify-between flex-wrap">
                <span>
                    <vs-chip
                        :color="timeColour(willsToContact.oldest, 'contact')"
                    >
                        {{ willsToContact.total }}
                    </vs-chip>
                </span>
                <span>
                    <vs-button
                        color="primary"
                        type="border"
                        to="/wills/pipeline/to_contact"
                        >View All</vs-button
                    >
                </span>
            </div>
        </div>
    </vx-card>
</template>

<script>
import ToContactTable from '../Table/ToContact'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'ToContactCard',
    mixins: [tableHelpers],
    components: {
        ToContactTable,
    },
    computed: {
        willsToContact() {
            return this.$store.getters.willsToContact
        },
    },
    methods: {
        refreshContact(card) {
            this.$store.dispatch('willsToContactFetch')
            card.removeRefreshAnimation(2000)
        },
    },
}
</script>
