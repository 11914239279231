<template>
    <vs-table
        v-model="selected"
        @selected="handleSelected"
        :data="wills"
        stripe
    >
        <template slot="thead">
            <vs-th sort-key="full_name">Name</vs-th>
            <vs-th sort-key="paid_date">Date Paid</vs-th>
            <vs-th>Introducer</vs-th>
            <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].user.details.full_name">
                    {{ data[indextr].user.details.full_name }}
                </vs-td>

                <vs-td :data="data[indextr].paid_date">
                    <vx-tooltip
                        :color="timeColour(data[indextr].paid_date, 'check')"
                        title="Paid Date"
                        :text="formatDateAndTime(data[indextr].paid_date)"
                        position="bottom"
                    >
                        <span class="flex items-center px-2 py-1 rounded">
                            <div
                                class="h-3 w-3 rounded-full mr-2"
                                :class="
                                    'bg-' +
                                    timeColour(data[indextr].paid_date, 'check')
                                "
                            ></div>
                            {{
                                timeDifference(data[indextr].paid_date, 'check')
                            }}
                        </span>
                    </vx-tooltip>
                </vs-td>

                <vs-td :data="data[indextr].coupon_used">
                    {{ introducer(data[indextr].coupon_used) }}
                </vs-td>
                <vs-th>
                    <div
                        v-if="
                            data[indextr].payment_details &&
                            data[indextr].payment_details.will_amount
                        "
                        class="mr-3 mt-3"
                    >
                        <vx-tooltip
                            :text="`Paid £${
                                data[indextr].payment_details.will_amount / 100
                            } for Will`"
                            position="bottom"
                        >
                            <feather-icon icon="DollarSignIcon"></feather-icon>
                        </vx-tooltip>
                    </div>
                    <div
                        v-if="
                            data[indextr].payment_details &&
                            data[indextr].payment_details.printing_amount
                        "
                        class="mr-3 mt-3"
                    >
                        <vx-tooltip text="Paid for Printing" position="bottom">
                            <feather-icon icon="PrinterIcon"></feather-icon>
                        </vx-tooltip>
                    </div>
                    <div
                        v-if="
                            data[indextr].subscription_details &&
                            data[indextr].subscription_details.active
                        "
                        class="mr-3 mt-3"
                    >
                        <vx-tooltip
                            text="Active Subscription"
                            position="bottom"
                        >
                            <feather-icon icon="AwardIcon"></feather-icon>
                        </vx-tooltip>
                    </div>

                    <div v-if="data[indextr].update" class="mr-3 mt-3">
                        <vx-tooltip text="Update Will" position="bottom">
                            <feather-icon icon="AlertCircleIcon"></feather-icon>
                        </vx-tooltip>
                    </div>
                </vs-th>
            </vs-tr>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'ToCheckTable',
    mixins: [textFormat, tableHelpers],

    props: ['wills'],
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        handleSelected() {
            this.$router.push({
                name: 'will',
                params: { id: this.selected.id },
            })
        },
        introducer(value) {
            if (value) {
                if (value.broker) return value.broker.company_name
                if (value.charity) return value.charity.charity_name
            }
            return null
        },
    },
}
</script>
