<template>
    <div id="overview">
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <ToCheckCard></ToCheckCard>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <ToContactCard></ToContactCard>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <ToApproveCard></ToApproveCard>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <ToPrintCard></ToPrintCard>
            </div>
        </div>
    </div>
</template>
<script>
import ToContactCard from './Cards/ToContact'
import ToApproveCard from './Cards/ToApprove'
import ToPrintCard from './Cards/ToPrint'
import ToCheckCard from './Cards/ToCheck'

export default {
    name: 'Wills',
    components: {
        ToContactCard,
        ToApproveCard,
        ToPrintCard,
        ToCheckCard,
    },
}
</script>

<style lang="scss">
#overview {
    .overlay-card {
        .vx-card__collapsible-content {
            max-height: 485px;
        }
    }

    .chat-card-log {
        height: 360px;

        .chat-sent-msg {
            background-color: #f2f4f7 !important;
        }
    }

    .card-video {
        .video-js {
            height: 370px;
        }
    }
}
</style>
