var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-table',{attrs:{"data":_vm.wills,"stripe":""},on:{"selected":_vm.handleSelected},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].user.details}},[_c('span',{on:{"click":function($event){return _vm.gotoWill(data[indextr].id)}}}),_vm._v(" "+_vm._s(data[indextr].user.details.full_name)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].checked_date}},[_c('vx-tooltip',{attrs:{"color":_vm.timeColour(data[indextr].checked_date, 'print'),"title":"Paid Date","text":_vm.formatDateAndTime(data[indextr].checked_date),"position":"bottom"}},[_c('span',{staticClass:"flex items-center px-2 py-1 rounded"},[_c('div',{staticClass:"h-3 w-3 rounded-full mr-2",class:'bg-' +
                                _vm.timeColour(
                                    data[indextr].checked_date,
                                    'print'
                                )}),_vm._v(" "+_vm._s(_vm.timeDifference( data[indextr].checked_date, 'print' ))+" ")])])],1),_c('vs-td',{attrs:{"data":data[indextr].coupon_used}},[_vm._v(" "+_vm._s(_vm.introducer(data[indextr].coupon_used))+" ")]),_c('vs-th',[(
                        data[indextr].payment_details &&
                        data[indextr].payment_details.will_amount
                    )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":`Paid £${
                            data[indextr].payment_details.will_amount / 100
                        } for Will`,"position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon"}})],1)],1):_vm._e(),(
                        data[indextr].payment_details &&
                        data[indextr].payment_details.printing_amount
                    )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Paid for Printing","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}})],1)],1):_vm._e(),(
                        data[indextr].subscription_details &&
                        data[indextr].subscription_details.active
                    )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Active Subscription","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"AwardIcon"}})],1)],1):_vm._e(),(data[indextr].update)?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Update Will","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1)],1):_vm._e()])],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"full_name"}},[_vm._v("Name")]),_c('vs-th',{attrs:{"sort-key":"checked_date"}},[_vm._v("Date Approved")]),_c('vs-th',[_vm._v("Inroducer")]),_c('vs-th')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }