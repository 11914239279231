<template>
    <vx-card
        v-if="willsToPrint"
        title="Print"
        collapse-action
        refresh-content-action
        @refresh="refreshPrint"
    >
        <ToPrintTable :wills="willsToPrint.data" class="mb-6"></ToPrintTable>
        <div v-if="willsToPrint.total">
            <div class="flex justify-between flex-wrap">
                <span>
                    <vs-chip :color="timeColour(willsToPrint.oldest, 'print')">
                        {{ willsToPrint.total }}
                    </vs-chip>
                </span>
                <span>
                    <vs-button
                        color="primary"
                        to="/wills/pipeline/to_print"
                        type="border"
                        >View All</vs-button
                    >
                </span>
            </div>
        </div>
    </vx-card>
</template>

<script>
import ToPrintTable from '../Table/ToPrint'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'ToPrintCard',
    mixins: [tableHelpers],
    components: {
        ToPrintTable,
    },
    computed: {
        willsToPrint() {
            return this.$store.getters.willsToPrint
        },
    },
    methods: {
        refreshPrint(card) {
            this.$store.dispatch('willsToPrintFetch')
            card.removeRefreshAnimation(2000)
        },
    },
}
</script>
