<template>
    <vx-card
        v-if="willsToCheck"
        title="Check"
        collapse-action
        refresh-content-action
        @refresh="refreshCheck"
    >
        <ToCheckTable :wills="willsToCheck.data" class="mb-6"></ToCheckTable>
        <div v-if="willsToCheck.total">
            <div class="flex justify-between flex-wrap">
                <span>
                    <vs-chip :color="timeColour(willsToCheck.oldest, 'check')">
                        {{ willsToCheck.total }}
                    </vs-chip>
                </span>
                <span>
                    <vs-button
                        color="primary"
                        type="border"
                        to="/wills/pipeline/to_check"
                        >View All</vs-button
                    >
                </span>
            </div>
        </div>
    </vx-card>
</template>

<script>
import ToCheckTable from '../Table/ToCheck'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'ToCheckCard',
    mixins: [tableHelpers],
    components: {
        ToCheckTable,
    },
    computed: {
        willsToCheck() {
            return this.$store.getters.willsToCheck
        },
    },
    methods: {
        refreshCheck(card) {
            this.$store.dispatch('willsToCheckFetch')
            card.removeRefreshAnimation(2000)
        },
    },
}
</script>
